import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../../components/Layout/Layout'
import GraphQLErrorList from '../../components/graphql-error-list'
import BlogPost from '../../components/blog-post'
import SEO from '../../components/seo'
import {toPlainText} from '../../lib/helpers'

export const query = graphql`
  query BlogPostTemplateQuery($slug: String) {
    post: sanityPost(slug: { current: { eq: $slug } }) {
      id
      mainImage {
        ...SanityImage
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
    }
  }
`


const BlogPostTemplate = props => {
  const {data, errors} = props
  const post = data && data.post
  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />}

      {errors && (
        <>
          <GraphQLErrorList errors={errors} />
        </>
      )}

      {post && <BlogPost {...post} />}
    </Layout>
  )
}

export default BlogPostTemplate
