import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from '../components/PortableText/PortableText'



function BlogPost (props) {


  const {_rawBody, title, mainImage, publishedAt, } = props
  return (
    <div className="container">
        <article className="article">
          <div className="article__heading">
              <h1 className="article__title">
                {title}
              </h1>
              
              {publishedAt && (
                <p className="article__date">
                  {differenceInDays(new Date(publishedAt), new Date()) > 3
                    ? distanceInWords(new Date(publishedAt), new Date())
                    : format(new Date(publishedAt), 'MMMM Do, YYYY')}
                </p>
              )}
              
          </div>
          <div className="article__banner">
              {mainImage && mainImage.asset && (
                  <div className="blog__image">
                    <img
                      src={imageUrlFor(buildImageObj(mainImage))
                        .width(1200)
                        .height(Math.floor((9 / 16) * 1200))
                        .fit('crop')
                        .auto('format')
                        .url()}
                      alt={mainImage.alt}
                    />
                  </div>
                )}
          </div>
          <div className="article__body">
          {_rawBody && <PortableText blocks={_rawBody} />}
          </div>
      </article>
    </div>    
  )
}

export default BlogPost



